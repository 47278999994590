<template>
  <div>
    <BaseBtn
      class="q-pr-sm"
      :class="btnClass"
      v-bind="btnAttrs"
      @click="showModal = true"
      :disable="!contactable"
    >
      <q-tooltip v-if="!contactable">
        Non è stata inserira la mail del cliente
      </q-tooltip>
    </BaseBtn>

    <EmailMessageModal
      v-model="showModal"
      @hide="showModal = false"
      :to="customer.email"
      :from="user.email"
    />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import useAuth from '../../hooks/auth'
import EmailMessageModal from '../email-messages/EmailMessageModal.vue'

export default {
  name: 'CustomerContact',
  inheritAttrs: false,
  props: {
    btnClass: {
      type: String,
      default: '',
    },
    customer: {
      type: Object,
    },
  },
  setup(props, ctx) {
    const btnAttrs = computed(() => {
      return {
        icon: 'mdi-send-circle-outline',
        label: 'Contatta',
        color: 'pink',
        ...ctx.attrs,
      }
    })

    const contactable = computed(() => !!props.customer.email)

    const showModal = ref(false)

    const { user } = useAuth()

    return {
      btnAttrs,
      contactable,
      showModal,
      user,
    }
  },
  components: { EmailMessageModal },
}
</script>
