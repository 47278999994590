<template>
  <BaseSelect
    use-input
    ref="select"
    hide-dropdown-icon
    v-bind="{ label: 'Cerca tra i modelli di messaggio', ...$attrs }"
    v-on="{ filter, ...$listeners }"
    display-value=""
    v-model="email"
    :options="state.data"
  >
    <template #prepend>
      <q-icon name="mdi-magnify" />
    </template>

    <template #no-option>
      <q-item>
        <q-item-section
          class="text-grey justify-between items-center"
          style="flex-direction: row !important"
        >
          <span class="q-mr-sm"> Nessun risultato </span>
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="scope">
      <q-item v-bind="scope.item.itemProps" v-on="scope.item.itemEvents">
        <q-item-section>
          <q-item-label>
            {{ scope.item.opt.subject }}
          </q-item-label>
          <q-item-label caption>
            Creato da {{ scope.item.opt.creator }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>

    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </BaseSelect>
</template>

<script>
import useApiCrud from '../../hooks/apiCrud.js'
import EmailMessages from '../../apis/emailMessages.js'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'EmailMessageAutocomplete',

  inheritAttrs: false,

  setup(_, ctx) {
    const query = ref('')

    const email = computed({
      set(value) {
        ctx.emit('select', value)
      },
      get() {
        return ''
      },
    })

    const { apiIndex, state } = useApiCrud({
      ctx,
      api: EmailMessages,
      routed: false,
    })

    function filter(query, update, abort) {
      if (!query.length) {
        abort()
        return
      }

      apiIndex({ query }).then((response) => {
        update(response.data)
      })
    }

    return {
      filter,
      email,
      query,
      state,
    }
  },
}
</script>
