<template>
  <BaseModal
    v-bind="{ ...$attrs }"
    @hide="$emit('hide')"
    @close="$emit('hide')"
    @confirm="send"
    size="lg"
    confirm
    label="Invia"
    icon="mdi-send-circle-outline"
    title="Nuovo messaggio"
  >
    <BaseInnerLoading :showing="sending" />

    <div class="q-gutter-md">
      <BaseInput
        label="Destinatario"
        type="email"
        v-model="data.to"
        error-name="to"
        :errors="errors"
      >
        <template v-slot:prepend>
          <q-icon name="mdi-at" />
        </template>
      </BaseInput>

      <UserAutocomplete
        class="q-mb-md"
        label="Mittente (Rispondi a)"
        v-if="fromUser"
        v-model="data.from"
        emit-value
        option-value="email"
        option-label="name"
        @select="setModel"
      >
        <template v-slot:after>
          <BaseBtn
            icon="mdi-pencil-outline"
            class="q-py-sm"
            color="pink"
            @click="fromUser = false"
          >
            <q-tooltip>Inserisci un indirizzo a mano</q-tooltip>
          </BaseBtn>
        </template>
      </UserAutocomplete>

      <BaseInput
        label="Mittente (Rispondi a)"
        type="email"
        v-model="data.from"
        error-name="from"
        v-if="!fromUser"
        :errors="errors"
      >
        <template v-slot:prepend>
          <q-icon name="mdi-reply-outline" />
        </template>

        <template v-slot:after>
          <BaseBtn icon="mdi-undo" class="q-py-sm" @click="fromUser = true">
            <q-tooltip>Annulla inserimento manuale</q-tooltip>
          </BaseBtn>
        </template>
      </BaseInput>

      <EmailMessageAutocomplete
        class="q-mb-md"
        label="Carica un modello esistente"
        v-if="load"
        @select="setModel"
      >
        <template v-slot:after>
          <BaseBtn icon="mdi-undo" class="q-py-sm" @click="load = false">
            <q-tooltip>Annulla ricerca modello</q-tooltip>
          </BaseBtn>
        </template>
      </EmailMessageAutocomplete>

      <BaseInput
        label="Oggetto"
        v-if="!load"
        v-model="data.subject"
        error-name="subject"
        :errors="errors"
      >
        <template v-slot:prepend>
          <q-icon name="mdi-tag-outline" />
        </template>

        <template v-slot:after>
          <BaseBtn
            icon="mdi-email-search-outline"
            class="q-py-sm"
            color="pink"
            @click="load = true"
          >
            <q-tooltip>Carica un modello di messaggio</q-tooltip>
          </BaseBtn>
        </template>
      </BaseInput>

      <BaseCheckbox
        v-model="save"
        :disable="!canSaveModel"
        label="Salva come nuovo modello"
        style="margin-top: -5px"
      >
        <q-tooltip v-if="!canSaveModel">
          Non è possibile salvare un modello esistente senza modificarlo
        </q-tooltip>
      </BaseCheckbox>

      <BaseInput
        label="Messaggio"
        type="textarea"
        v-model="data.message"
        error-name="message"
        :errors="errors"
      >
        <template v-slot:prepend>
          <q-icon name="mdi-text" />
        </template>
      </BaseInput>

      <BaseInput
        label="Piè di pagina"
        type="textarea"
        v-model="data.footer"
        error-name="footer"
        :errors="errors"
      />
    </div>
  </BaseModal>
</template>

<script>
import EmailMessageAutocomplete from './EmailMessageAutocomplete.vue'
import EmailMessages from '../../apis/emailMessages.js'
import { computed, reactive, ref } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import useApiErrors from '../../hooks/apiErrors'
import useNotify from '../../hooks/notify.js'
import useToggler from '../../hooks/toggler'
import UserAutocomplete from '../users/UserAutocomplete.vue'

export default {
  name: 'EmailMessageModal',

  components: {
    EmailMessageAutocomplete,
    UserAutocomplete,
  },

  props: {
    to: {
      type: String,
      required: true,
    },

    from: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const errors = useApiErrors()
    const { success } = useNotify()

    const load = ref(false)

    const loadedModel = ref({
      subject: '',
      message: '',
      footer: '',
    })

    const fromUser = ref(true)

    const { toggler: sending, on: startSend, off: stopSend } = useToggler()

    const data = reactive({
      subject: '',
      message: '',
      footer: '',
      to: props.to,
      from: props.from,
    })

    const save = ref(false)

    function validate() {
      errors.clearError()

      const occurence = {}

      if (!data.from) occurence.from = ['Inserisci un destinatario valido']
      if (!data.to) occurence.to = ['Inserisci un mittente valido']
      if (!data.subject) occurence.subject = ['Inserisci un oggetto']
      if (!data.message) occurence.message = ['Inserisci un testo']

      if (!isEmpty(occurence)) {
        errors.setError({
          message: 'Ricontrolla i dati',
          errors: occurence,
        })
      }
    }

    const canSaveModel = computed(() => {
      const { subject, message, footer } = data
      return !isEqual(loadedModel.value, { subject, message, footer })
    })

    async function send() {
      validate()

      if (errors.hasError()) return

      startSend()

      try {
        await EmailMessages.send({ ...data, save: save.value })

        //reset form
        data.subject = ''
        data.message = ''
        data.footer = ''
        data.to = props.to
        data.from = props.from

        save.value = false

        success('Messaggio inviato correttamente')

        emit('hide')
      } catch (e) {
        errors.setError(e)
      } finally {
        stopSend()
      }
    }

    function setModel(value) {
      load.value = false
      save.value = false

      const { subject, message, footer } = value

      data.subject = subject
      data.message = message
      data.footer = footer

      //set original loaded model
      loadedModel.value = { subject, message, footer }
    }

    return {
      errors,
      data,
      load,
      loadedModel,
      fromUser,
      setModel,
      send,
      sending,
      canSaveModel,
      save,
    }
  },
}
</script>
