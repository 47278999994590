<template>
  <BaseSelect
    use-input
    input-debounce="0"
    ref="select"
    v-bind="{ label: 'Cerca tra gli utenti', ...$attrs }"
    v-on="{ filter, ...$listeners }"
    :value="value"
    :options="options"
    :display-value="
      value.name ? value.name : typeof value == 'string' ? value : ''
    "
    @input-value="inputValue"
  >
    <template #prepend>
      <q-icon name="mdi-magnify" />
    </template>

    <template #no-option>
      <q-item>
        <q-item-section
          class="text-grey justify-between items-center"
          style="flex-direction: row !important"
        >
          <span class="q-mr-sm">
            Nessun risultato per "<strong>{{ query }}</strong
            >"
          </span>
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="scope">
      <q-item v-bind="scope.item.itemProps" v-on="scope.item.itemEvents">
        <q-item-section>
          <q-item-label>{{ scope.item.opt.name }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>

    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </BaseSelect>
</template>

<script>
import { useActions, useGetters } from '../../hooks/store.js'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'UserAutocomplete',
  inheritAttrs: false,

  props: {
    inputPreserveValue: Boolean,
    value: {
      type: [Object, String],
    },
  },

  setup({ inputPreserveValue }, { emit }) {
    const { load: loadUsers } = useActions('user', ['load'])
    const { filter: filterUsers } = useGetters('user', ['filter'])
    loadUsers()

    const query = ref('')
    const options = computed(() => {
      return filterUsers(query.value).value
    })

    function filter(query, update) {
      update(filterUsers(query))
    }

    function inputValue(value) {
      query.value = value

      if (!inputPreserveValue && value.length > 0) emit('input', {})
    }

    return {
      filter,
      inputValue,
      query,
      options,
    }
  },
}
</script>
